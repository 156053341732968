



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { meetingsStore } from '@/store'
import { Contact } from '@tada-team/tdproto-ts'

@Component({
  components: {
    IconPlus: () => import('@tada/icons/dist/IconPlus.vue'),
  },
})
export default class MeetingCreateBtn extends Vue {
  @Prop({
    type: Object,
  }) contact?: Contact

  private openMeetingDialog () {
    const from = this.$route.name !== 'Calendar' ? 'правой колонки' : 'Календаря'
    const goal = `Создать встречу — «+» из ${from}`
    window.goal('Calendar', { Calendar: goal })

    const member = this.contact?.jid
    const members = member ? [member] : []

    meetingsStore.actions.openMeetingDialog({ template: { members } })
  }
}

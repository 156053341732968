import { render, staticRenderFns } from "./CreateBtn.vue?vue&type=template&id=7ff497da&scoped=true&"
import script from "./CreateBtn.vue?vue&type=script&lang=ts&"
export * from "./CreateBtn.vue?vue&type=script&lang=ts&"
import style0 from "./CreateBtn.vue?vue&type=style&index=0&id=7ff497da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ff497da",
  null
  
)

export default component.exports
import {QBtn,QTooltip,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QTooltip,QIcon})
